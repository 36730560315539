/* 
    Welcome to Storybook Websites! 
    Below is the code for both the home 
    and about pages (both contained 
    so many like elements 
    and the same exact functionality that
    it didn't make sense to use a new file.)
    
    The app has 5 main features, animations 
    being the largest, which the code below
    reflects (i.e., anything that required
    custom functionality was grouped
    together as a property on the app obj).
    I also separated the code into
    5 main sections based on those features, 
    so it's easier to read--or 6 main sections,
    if we include helper functions.
    
    (NOTE: all code was purposely written in ES5
    to cut down on the bundle size, among
    other reasons.)
*/

// a high level overview of the application
// in terms of features, also serves as
// our app's namespace
var app = {
  ready: function (callback) {
    if (document.readyState != "loading") {
      callback();
    } else {
      document.addEventListener("DOMContentLoaded", callback);
    }
  },
  menu: {},
  animations: { elementsToAnimate: [] },
  contact: {},
  testimonial: {},
  smoothScroll: {},
  state: {
    // needed so our animation functions can check if animations are in process of updating
    // so we don't update twice in one frame
    isUpdating: false,

    // so we can use this in scroll handlers without querying it every time (only updated on resize events)
    windowHeight: window.innerHeight,

    // for canceling setTimeouts used in app to handle delaying animations for groups of elements
    timeoutIds: new Set(), // set because these are unique
  },
  globals: {
    // 960px width, app defined break between mobile and desktop
    MOBILE_SIZE: 960,

    // used to detect if on mobile. Note, it's not a solid approach, since user agent sniffing is an ever changing target
    // and touch devices include desktops these days. However, it's accurate enough for our purposes here.
    isMobile:
      window.navigator.maxTouchPoints ||
      "ontouchstart" in document ||
      window.navigator.userAgent.match(/Trident|Edge/) !== null,
  },
};

/* HELPER FUNCTIONS
===================================================================*/

// generic event listener for a single element
app.listen = function (obj, event, callback, useCapture) {
  obj = typeof obj === "string" ? app.select(obj) : obj;
  obj.addEventListener(event, callback, useCapture);
};

// event listener for array of dom elements
app.listenAll = function (objs, event, callback, useCapture) {
  objs = typeof objs === "string" ? app.selectAllToArray(objs) : objs;
  objs.forEach(function (obj) {
    app.listen(obj, event, callback, useCapture);
  });
};

// functions to help facilitate turning nodelists into arrays,
// useful for older browsers so we can use array methods on them
app.selectToArray = function (queryString, root) {
  return [app.select(queryString, root)];
};

app.selectAllToArray = function (queryString, root) {
  return [].slice.call(app.selectAll(queryString, root));
};

app.select = function (queryString, root) {
  return root
    ? root.querySelector(queryString)
    : document.querySelector(queryString);
};

app.selectAll = function (queryString, root) {
  return root
    ? root.querySelectorAll(queryString)
    : document.querySelectorAll(queryString);
};

app.throttle = function (func, limit) {
  var inThrottle = null;
  var event = null;

  var throttledHandler = function (e) {
    var args = arguments;
    var context = this;

    event = e;

    if (!inThrottle) {
      func.apply(context, args);
      event = null;

      inThrottle = setTimeout(function () {
        inThrottle = null;

        if (event) {
          throttledHandler.call(context, event);
        }
      }, limit);
    }
  };

  return throttledHandler;
};

// prevents rare case of viewport jumping right (screen resizing widthwise) on resize/scroll.
// (happens because we have absolutely positioned elems off screen that animate in,
// which can randomly cause the layout to expand on some browsers as soon as the animation
// is activated).
app.preventLayoutResize = function () {
  // Need all three below because certain browsers will only work with a certain
  // one in exclusion of the rest (e.g., for ie only document.body works; the rest are ignored);
  app.select("html").scrollLeft = 0;
  document.documentElement.scrollLeft = 0;
  document.body.scrollLeft = 0;
};

/* SMOOTH SCROLL FUNCTIONS
=====================================================================*/

app.smoothScroll.animateFrame = function (
  options // ({duration, timing, draw})
) {
  options = options || {};
  var duration = options.hasOwnProperty("duration") ? options.duration : 1000;
  var timing = options.timing;
  var draw = options.draw;

  var start = null;

  requestAnimationFrame(function animateFrame(time) {
    start = start ? start : window.performance.now();

    // timeFraction goes from 0 to 1
    var timeFraction = (time - start) / duration;

    if (timeFraction > 1) {
      timeFraction = 1;
    }

    // calculate the current animation state.
    // The animation timing function could be whatever we choose (e.g., for linear, just return the timeFraction).
    var progress = timing(timeFraction);

    draw(progress); // draw the animation

    if (timeFraction < 1) {
      requestAnimationFrame(animateFrame);
    }
  });
};

app.smoothScroll.changeScrollY = function (
  elemPosition,
  scrollStartPosition,
  progress
) {
  var scrollPosition = progress * elemPosition + scrollStartPosition;

  // all 3 below do the same: set Y scroll to certain position. However, depending on what
  // browser the user is on, only one of the 3 will work (e.g., html.scrollTop works on chrome,
  // but only document.body.scrollTop works on ie, etc)
  app.select("html").scrollTop = scrollPosition;
  document.body.scrollTop = scrollPosition;
  document.documentElement.scrollTop = scrollPosition;
};

app.smoothScroll.easeScroll = function (timeFraction) {
  return Math.sin(timeFraction * (Math.PI / 2)); // sinusoidal easeout
};

app.smoothScroll.scrollToSection = function (e) {
  e.preventDefault(); // to prevent jumping to hash;

  var elemPosition = app.select(this.hash).getBoundingClientRect().top;
  var scrollStartPosition = window.pageYOffset;

  app.smoothScroll.animateFrame({
    duration: 1000,
    timing: app.smoothScroll.easeScroll,
    draw: function (progress) {
      app.smoothScroll.changeScrollY(
        elemPosition,
        scrollStartPosition,
        progress
      );
    },
  });
};

/* CONTACT FUNCTIONS
================================================================================*/

// for clientside validation, and
// also does the original xhr since we
// do server validation right away if
// clientside passes.
// returns validationResult obj
app.contact.validateInputs = function () {
  var validationResult = {
    failedClientValidation: false,
    failedServerValidation: false,
    connectionError: false,
    xhr: null,
  };

  var answerInput = app.select("#answer");
  var userAnswer = answerInput.value.trim().toLowerCase();

  // both in case user decides to answer
  // in digits or letters
  var captchaNumAnswer = 13;
  var captchaWordAnswer = "thirteen";

  // we could rely only on server side validation,
  // but checking here too helps in the case someone/a bot
  // tries to continually submit bad data to the server.
  // But if the 'captcha' is bad, we won't even bother to send.

  if (
    Number(userAnswer) !== captchaNumAnswer &&
    userAnswer !== captchaWordAnswer
  ) {
    answerInput.value = "";

    validationResult.failedClientValidation = true;
  } // try server validation
  else {
    var inputs = app.selectAllToArray("input:not(#submit)");
    var msg = app.select("textarea");

    var dataForServer = {};

    inputs.forEach(function (input) {
      dataForServer[input.id] = input.value;
    });

    // since it already passed
    // validation above, we just give
    // it the answer here.
    dataForServer.answer = captchaNumAnswer;
    dataForServer.message = msg.value;

    var xhr = new XMLHttpRequest();

    xhr.open("POST", "/");
    xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
    xhr.send(JSON.stringify(dataForServer));

    validationResult.xhr = xhr;
  }

  return validationResult;
};

app.contact.displayValidationErrors = function (validationResult) {
  var validationMsgElem = app.select(".validation");

  // it's possible this function was already called and error animations from previous tries are still displaying,
  // hence we need to clear them out (reset class and cause reflow) else new error animation/message will not show
  validationMsgElem.className = "validation";
  validationMsgElem.offsetHeight;
  validationMsgElem.className = "validation error";

  if (validationResult.failedClientValidation) {
    validationMsgElem.innerHTML =
      "<em>Wrong answer. Please enter the right answer to the math problem.</em>";
  } else if (validationResult.failedServerValidation) {
    var errorText = "Please correct the following error(s): <br/>";

    var validationErrors = validationResult.validationErrors; // from server

    validationErrors.forEach(function (error) {
      var invalidInput = app.select("#" + error.param);
      invalidInput.value = "";

      errorText += error.msg + "<br/>";
    });

    var validationAnimationDuration =
      app.animations.getDurationInMS(
        getComputedStyle(validationMsgElem).animationDuration
      ) * validationErrors.length; // alter animation duration based on number of error messages.
    validationMsgElem.style.animationDuration =
      validationAnimationDuration + "ms";
    validationMsgElem.innerHTML = "<em>" + errorText + "</em>";
  } else if (validationResult.connectionError) {
    validationMsgElem.style.animationDuration = "12s";
    validationMsgElem.innerHTML =
      "<em>Unable to send message.</br> " +
      "Please make sure you're connected to the internet, and then try again.</em>";
  }
};

// clears out form elements so we can see send result message
app.contact.animateOutExistingFormElements = function () {
  // successGroup includes all inputs and text of form,
  // which we want to fade so we can display send success msg
  // over entire form
  var successGroup = app.selectAllToArray(".success-group").reverse(); // because we want animation to play from message box back to beginning of input form

  // need to reset animation state for all these because successGroup
  // elements already had an initial animation attached to them.
  successGroup.forEach(function (elem, i) {
    var staggerDelay = 100;

    elem.style.animation = "none";
    elem.offsetHeight;
    elem.style.animation = "";
    elem.style.opacity = 1;
    elem.style.transform = "scale(1,1)";
    elem.className += " success animate";
    elem.style.animationDelay = staggerDelay * i + "ms";
  });
};

// displays (and auto scrolls to) success msg (99.9% of time) or in
// extremely rare cases will display api error msg.
app.contact.animateInSendResultMsg = function (response) {
  var validationMsgElem = app.select(".validation");

  // need to reset className since other class with animation might be attached.
  validationMsgElem.className = "validation success";

  // always want the big bold success lettering, so we just change color if actual api error
  validationMsgElem.style.color =
    typeof response.emailAPIErrorMsg !== "undefined" ? "red" : "";

  validationMsgElem.innerHTML =
    "<em>" +
    (typeof response.emailAPIErrorMsg !== "undefined"
      ? response.emailAPIErrorMsg
      : response.msg) +
    "</em>";

  var offset = 300;
  var msgPosition = validationMsgElem.getBoundingClientRect().top - offset; // small offset so element is scrolled a bit over its top
  var scrollStartPosition = window.pageYOffset;

  setTimeout(function smoothScrollToMsg() {
    app.smoothScroll.animateFrame({
      duration: 1000,
      timing: app.smoothScroll.easeScroll,
      draw: function (progress) {
        app.smoothScroll.changeScrollY(
          msgPosition,
          scrollStartPosition,
          progress
        );
      },
    });
  }, 1200); // 1200ms, a bit arbitrary, when message animation is almost finished, before we scroll
};

/* TESTIMONIAL FUNCTIONS
===================================================================================*/

// Testimonials, after animating in,
// each display for a certain amount of time before animating out.
// Fn rearranges each testimonial part's animation cycle,
// starting a new cycle for each part: switching to a new testimonial
// is basically just starting its CSS animation right away (animating it in) and delaying
// the same animations for the yet to be seen testimonials.
app.testimonial.handleTestimonialSwitch = function () {
  var selectAllToArray = app.selectAllToArray;

  // Each testimonial of the test section is made up of five elements.
  // We need to find which testimonial we're on and change each of the
  // five to the new testimonial.
  var testHighlights = selectAllToArray(".test__highlight");
  var testPersons = selectAllToArray(".test__person");
  var testJobTitles = selectAllToArray(".test__position");
  var testSliderBubbles = selectAllToArray(".test__slider__bubble");
  var testDescs = selectAllToArray(".test__description");
  var testDescsLetters = [];

  // we only need letters for the description (on desktop)
  // or use the whole description (on mobile) for animation
  testDescs.forEach(function (desc) {
    // we reverse this one because we want desc to slide in from the left,
    // last letter first to slid in (also first to slide out upon exit animation);
    testDescsLetters.push(
      app.globals.isMobile ? desc : selectAllToArray(".letter", desc).reverse()
    );
    // REMINDER: same line of code above appears in readyElementsForAnimation fn, but
    // above we don't slice desc for mobile because we don't need each element to be
    // array, since switch testimonial adds the animation to the element
    // (for elems without animated letters) without calling the animate fn,
    // which expects an array.
  });

  // if bubble (i.e., testimony we're on) isn't active opacity will be 0
  if (getComputedStyle(this).backgroundColor === "rgba(0, 0, 0, 0)") {
    // TODO: change to use/check for active class
    var clickedBubbleIndex = 0;

    testSliderBubbles.forEach(function (bubble, i) {
      if (bubble === this) {
        clickedBubbleIndex = i;
      }
    }, this);

    var switchAnimationCycle = app.testimonial.switchAnimationCycle;

    switchAnimationCycle(testSliderBubbles, clickedBubbleIndex, false);
    switchAnimationCycle(testHighlights, clickedBubbleIndex, false);
    switchAnimationCycle(testPersons, clickedBubbleIndex, false);
    switchAnimationCycle(testJobTitles, clickedBubbleIndex, false);
    switchAnimationCycle(
      testDescsLetters,
      clickedBubbleIndex,
      app.globals.isMobile ? false : true
    );
  } // background is black (already active)
  else {
    // do nothing, current testimonial is already showing
  }
};

// Each testimonial is made up of 5 elements or parts. This fn is responsible
// for switching to, animating in, one of the elements of
// the testimonial, while delaying the other like ones (e.g., animates in
// the desc of the test just clicked on, while the other descs animations
// are delayed so they animate in after the appropriate amount of time).
// @Params
// elementsToSwitch: all elements of some part of the testimonial
//    (e.g., highlight, person, jobTitle, descLetters/desc, bubble)
//
// clickedBubbleIndex: which bubble user clicked on, which corresponds to
//     which testimonial user clicked
//
// hasAnimatedLetters: bool for whether or not element of testimonial has animated Letters
//     (this only applies for the actual desc/text of the testimonial).
app.testimonial.switchAnimationCycle = function (
  elementsToSwitch,
  clickedBubbleIndex,
  hasAnimatedLetters
) {
  // could grab all of any part of a testimonial for this
  var numOfTestimonials = app.selectAllToArray(".test__slider__bubble").length;

  // 0 below is arbitrary. If it's letters we're animating,
  // we can grab any from the array and get the info we need.
  var elementWithAnimation = hasAnimatedLetters
    ? elementsToSwitch[clickedBubbleIndex][0]
    : elementsToSwitch[clickedBubbleIndex];

  var animationDuration = app.animations.getDurationInMS(
    getComputedStyle(elementWithAnimation).animationDuration
  );

  // second letter of first letter array ([0][1]) will always have the first
  // delay since we calc the stagger by using delayMultiplicand * i (this basically
  // gives us back the original multiplicand) and uniqueDelay is untouched
  // after the first time animation is added with animate fn (from here on, testimonial
  // animations are handled with configureAnimation fn)
  var lettersDelay = hasAnimatedLetters
    ? elementsToSwitch[0][1].uniqueDelay
    : 0;

  var groupDelay = animationDuration / numOfTestimonials; // animation delay for each group of test elems (e.g., letters, highlights, etc)

  elementsToSwitch.forEach(function (element) {
    if (hasAnimatedLetters) {
      element.forEach(function (letter) {
        // remove since we're animating a new testimonial
        letter.classList.remove("animate");
        void letter.offsetWidth; // cause reflow
      });
    } else {
      element.classList.remove("animate");
      void element.offsetWidth;
    }
  });

  var testInfo = {
    // sometimes we use shorthand name (test for testimonial), unless it could cause confusion
    // (testElem, which could be confused for an elem we're testing for something).
    testimonialElem: elementsToSwitch[clickedBubbleIndex],
    hasAnimatedLetters: hasAnimatedLetters,
    groupDelay: groupDelay,
    lettersDelay: lettersDelay,
    delayMultiplier: 0, // we're animating in just clicked test first, so we don't want delay or it to be mult by anything
  };

  var configureAnimation = app.testimonial.configureAnimation;

  configureAnimation(testInfo); // for just clicked test

  // testimonials are designed like so: O O O O
  // (i.e., a bunch of bubbles, styled as circles, to click on to display some testimonial)
  // this code finds which one user clicked on and sets animation (and delay) for those
  // test elems that weren't clicked.
  if (
    clickedBubbleIndex > 0 &&
    clickedBubbleIndex !== elementsToSwitch.length - 1
  ) {
    // if user clicked on bubble somewhere in middle
    testInfo.delayMultiplier = 1;

    // start i at index one ahead of bubble user clicked on, since we
    // already set animation delay of bubble clicked above.
    // This is for the animations of the next testimonials animating in after
    // their delay expires.
    for (
      var i = clickedBubbleIndex + 1;
      i < elementsToSwitch.length;
      i++, testInfo.delayMultiplier++ // second half of array
    ) {
      testInfo.testimonialElem = elementsToSwitch[i];

      configureAnimation(testInfo);
    }

    for (
      i = 0;
      i < clickedBubbleIndex;
      i++, testInfo.delayMultiplier++ // go back to first half
    ) {
      testInfo.testimonialElem = elementsToSwitch[i];

      configureAnimation(testInfo);
    }
  } // user clicked on first or last bubble
  else {
    testInfo.delayMultiplier = 1;

    for (i = 0; i < elementsToSwitch.length; i++) {
      if (i !== clickedBubbleIndex) {
        // want to skip this one because animation on clicked bubble is already set and delay is 0
        testInfo.testimonialElem = elementsToSwitch[i];

        configureAnimation(testInfo);

        testInfo.delayMultiplier++;
      }
    }
  }
};

// adds animation and delays differently depending on if we're animating
// letters or not (e.g., the whole paragraph). Reason we don't use our
// app.animations.animate fn for this is because that's concerned only
// with our scroll animations, which, among other things, has to handle the
// groupDelay differently. (We technically could use setTimeouts here for
// groupDelay, but that will cause bugs, overlapping animations, if the
// tab is inactive for too long.)
app.testimonial.configureAnimation = function (testInfo) {
  var hasAnimatedLetters = testInfo.hasAnimatedLetters;
  var groupDelay = testInfo.groupDelay;
  var delayMultiplier = testInfo.delayMultiplier;
  var lettersDelay = testInfo.lettersDelay;
  var testimonialElem = testInfo.testimonialElem;

  if (hasAnimatedLetters) {
    // NOTE: groupDelay * delayMultiplier will only apply for
    // the testimonials that weren't clicked (i.e., ones
    // delaying to animate in)
    testimonialElem.forEach(function (letter, i) {
      letter.classList.add("animate");
      // Unlike our scroll animations, the groupDelay here does not need to use setTimeout.
      // We can incorporate it right into the actual animation delay. In short, we delay
      // when the animations for all letters of a group start, but we also want each subsequent
      // letter animated in (after the first letter starts its animation) to have a delay as well
      // (i.e., we stagger them).
      letter.style.animationDelay =
        groupDelay * delayMultiplier + i * lettersDelay + "ms";
    });
  } else {
    testimonialElem.classList.add("animate");
    testimonialElem.style.animationDelay = groupDelay * delayMultiplier + "ms";
  }
};

/* ANIMATION FUNCTIONS
===================================================================================*/

app.animations.isVisible = function (elem) {
  var windowHeight = app.state.windowHeight;

  var elementInsideViewport = elem.top < windowHeight && elem.bottom > 0;
  var viewportInsideElement = elem.bottom > windowHeight && elem.top < 0; // for elems bigger than visual viewport

  return elementInsideViewport || viewportInsideElement;
};

// get duration of animation duration or delay.
// always returns ms value
app.animations.getDurationInMS = function (duration) {
  var timeInMilliseconds = 0;

  if (duration.indexOf("ms") === -1) {
    timeInMilliseconds = parseFloat(duration) * 1000;
  } else {
    timeInMilliseconds = parseFloat(duration);
  }

  return timeInMilliseconds;
};

// wrap text (p tags, h tags, etc) in divs and spans.
// words are wrapped in divs, and letters in spans
// text is wrapped so we can animate single letters or
// whole words
//@PARAMS
// groupOfLetters: an array where each element is all the
//   text of an html element (e.g., text of h1, or p's with same class)
app.animations.wrapText = function (groupOfLetters) {
  groupOfLetters.forEach(function (group) {
    // need to push group to array because fn wraptext expects array of letters
    // and we might call it recursively below
    var wordArray = [];
    wordArray.push(group);

    group.setAttribute("aria-label", group.innerText); // set aria-label to be the text of group itself, since individual span wrapped letters will be ignored by some screen readers

    if (group.innerHTML.match(/<.+?>/g) !== null) {
      // if html has tags in it and not just text, we must rip tags out else the span will wrap around tags too
      // not an exhaustive list of void elems, but commonly used ones. Just for reference since matching /<.+?>/g regex matches those anyway
      // var voidElements = group.innerHTML.match(/<(img|br|\/br|br\/|br \/|hr|input).+?>/g);

      var cleanHTML = group.innerHTML.split(/<.+?>/g); // find html tags (eg. <a> <br> etc, within heading or paragraph text) and split remaining text at those tags
      var htmlTags = group.innerHTML.match(/<.+?>/g);
      var newHTML = "";

      if (group.innerHTML.match(/<div/g) === null) {
        // if no divs
        // build new innerHTML from cleanHTML with words wrapped in divs
        // and htmlTags inserted back into original positions
        for (var i = 0, j = 0; i < cleanHTML.length; i++) {
          newHTML +=
            cleanHTML[i].replace(
              /([^\s]+)/g,
              '<div class="word" aria-hidden="true" style="position: relative; display: inline-block">$&</div>'
            ) + (typeof htmlTags[j] !== "undefined" ? htmlTags[j++] : "");
        }
        group.innerHTML = newHTML;
        app.animations.wrapText(wordArray);
      } // words already wrapped in divs, so wrap letters in spans
      else {
        // same as with divs above but now we wrap each letter in a span
        for (i = 0, j = 0; i < cleanHTML.length; i++) {
          newHTML +=
            cleanHTML[i].replace(
              /\S/g,
              '<span class="letter" aria-hidden="true">$&</span>'
            ) + (typeof htmlTags[j] !== "undefined" ? htmlTags[j++] : "");
        }

        group.innerHTML = newHTML;
      }

      // need to build string inside newHTML string because once you place a tag into some innerHTML
      // closing tag will automatically be inserted next to it, which isn't what we want.
    } // no tags, so just wrap words of text in divs
    else {
      group.innerHTML = group.innerHTML.replace(
        /([^\s]+)/g,
        '<div class="word" aria-hidden="true" style="position: relative; display: inline-block">$&</div>'
      );
      app.animations.wrapText(wordArray);
    }
  });
};

// used as a callback to animationEnd fn
// these three little helper functions below
// are just there so it helps to know
// what we're changing display of,
// why we're setting width to zero, etc
app.animations.displaySpansInline = function () {
  this.style.display = "inline";
};

// used as a callback to animationEnd fn
// remove animation because sometimes we want
// to apply transitions or other animations to an elem
// many of which started off transparent
app.animations.removeAnimation = function () {
  this.style.opacity = "1";
  this.style.animation = "none";
};

// run some callback on elems after animation finishes
app.animations.animationEnd = function (elems, callback) {
  app.listenAll(elems, "animationend", callback);
};

// A FEW IMPORTANT AFTERTHOUGHTS ON THIS FUNCTION:
// This is a terrible and terribly long fn.
// Instead of generalizing, I tried to do something distinct
// with almost every element, hence all the individual querying,
// sorting, and setting up of unique properties.
// This is part of what prompted me to write my own scroll library,
// ECA (found here: ), to greatly simplify this.
//
// For instance, this same fn appears there where I do all the
// below in just a dozen lines.
//
// NOTE: This fn is left as is because
// in case I ever update the site in the future, I plan to
// rip out all the animation code and just bundle the rest
// with my animation library, which does all this faster
// and more concisely.
//
// Original fn comment below:
// This function is the first step to our animation pipeline.
// Animating an element on scroll is as simple as defining the
// animation for the element in css with a .animate class (but
// don't add the animate class in the html).
// This fn then selects elements we want to animate,
// pushes them into the elementsToAnimate array, and adds
// configurable animation properties to them (optional, since
// default properties are set up for us).
// The rest of the work is carried out for us by other
// animation functions. Note that animating letters involves
// an extra step or two, especially if you're selecting
// similar letters (section titles with same class) across
// sections.
app.animations.readyElementsForAnimation = function () {
  var selectToArray = app.selectToArray;
  var selectAllToArray = app.selectAllToArray;
  var select = app.select;
  var wrapText = app.animations.wrapText;

  var html = select("html");

  if (html.dataset.page === "home") {
    var heroTitle = selectToArray(".home-hero__title");
    // for animating single letters of headings and paras we must wrap them after we select elems
    wrapText(heroTitle);
    // then we must select the letters we just wrapped since these are what we animate
    var heroTitleLetters = selectAllToArray(".home-hero__title .letter");
    var heroSubtitle = selectToArray(".home-hero__subtitle");
    var heroTagline = selectToArray(".home-hero__title-tagline");
    var heroQuotes = selectAllToArray("figure");

    var benefitCardBehind = selectAllToArray(".benefits__card__behind");
    var benefitsCardMotif = selectAllToArray(".benefits__card__motif");
    var benefitsCardTitle = selectAllToArray(".benefits__card__title");
    wrapText(benefitsCardTitle);
    var benefitsCardDesc = selectAllToArray(".benefits__card__description");
    // wrapText(benefitsCardDesc);
    var servicesTitles = selectAllToArray(".services__service__title");
    var servicesImgsTri = selectAllToArray(".services__imgs__tri");
    var servicesImgsArt = selectAllToArray(".services__imgs__art");
    var serviceCardSubTitle = selectAllToArray(
      ".services__service__card__subtitle"
    );
    var serviceCardDesc = selectAllToArray(
      ".services__service__card__description"
    );
    var testHighlight = selectAllToArray(".test__highlight");
    var testDesc = selectAllToArray(".test__description");

    // we use a fancier animation for desktop, animating single letters of the whole
    // testimonial instead of the paragraph as a whole
    if (!app.globals.isMobile) {
      wrapText(testDesc); // wrap letters in spans to ready for animation
      testDesc.forEach(function (desc) {
        desc.style.opacity = "1"; // setting each paragraph itself back to 1 since on desktop we only set opacity of wrapped letters
      });
    }
    var testMotif = selectAllToArray(".test__motif");
    var testPerson = selectAllToArray(".test__person");
    var testPos = selectAllToArray(".test__position");
    var testSliderBubbles = selectAllToArray(".test__slider__bubble");
  }

  // elems specific to all pages
  var heroCTA = selectAllToArray(".home-hero__cta");
  var benefitsMotif = selectAllToArray(".section__motif");
  var sectionTitles = selectAllToArray(".section__title");
  wrapText(sectionTitles);
  var bookUnderline = selectAllToArray(".section__title__underline");

  var contact = selectToArray(".contact");
  var contactBox = selectToArray(".box");
  var contactMotif = selectToArray(".contact__motif");
  var contactTitle = selectToArray(".contact__title");
  var contactCTA = selectToArray(".contact__cta");
  var contactInputs = selectAllToArray(".box-group");
  var inputLabels = selectAllToArray(".input-field.box-group > label");
  var contactPhone = selectToArray(".contact-phone");
  var contactEmail = selectToArray(".contact-email");
  var footerAbout = selectToArray(".footer-about");
  wrapText(footerAbout);
  var footerAboutLetters = selectAllToArray(".footer-about .letter");
  var logoFooter = selectToArray(".logo-footer");
  var footerDesc = selectToArray(".footer-about__desc");
  wrapText(footerDesc);
  var footerDescLetters = selectAllToArray(".footer-about__desc .letter");
  var footerNavTitle = selectToArray(".footer__footer-nav__title");
  var navItem = selectAllToArray(".nav-item");
  navItem = navItem.reverse(); // so animation plays from last to first item
  var footerContactTitle = selectToArray(".footer__contact-info__title");
  var addressIcon = selectToArray(".address-icon");
  var contactInfo = selectAllToArray(".footer__contact-info__info");
  var emailIcon = selectToArray(".email-icon");
  var phoneIcon = selectToArray(".phone-icon");
  var followTitle = selectToArray(".footer__follow-info__title");
  var facebookIcon = selectToArray(".facebook-icon");
  var instagramIcon = selectToArray(".instagram-icon");
  var twitterIcon = selectToArray(".twitter-icon");
  var youtubeIcon = selectToArray(".youtube-icon");
  var aboutTitles = selectAllToArray(".about-title");
  wrapText(aboutTitles);

  if (html.dataset.page === "about") {
    var aboutHeroHeading = selectAllToArray(".about-hero__heading");
    wrapText(aboutHeroHeading);
    var aboutHeroHeadingLetters = selectAllToArray(
      ".about-hero__heading .letter"
    );
    var aboutHeroSubHeading = selectToArray(".about-hero__subheading");
    var aboutHeroTagline = selectToArray(".about-hero__title-tagline");
    var aboutCard = selectAllToArray(".about-story__part");
    var aboutStoryPartDesc = selectAllToArray(".about-story__part__desc");
  }

  // Build letter arrays:
  // these title and desc arrays below contain elems that are themselves arrays of letters
  var sectionTitleLetters = [];

  // because each section title has same class, selecting all the letters inside them
  // will simply put each elem, each section title letter, as one element of array
  // which we don't want, since we're animating using staggered delays
  // (delaying by multiples of whatever user specifies as the animation delay)
  // and if we didn't break them up like this (each elem its own array of letters for some section)
  // the end delay of one section would carry over to start of next section
  // (e.g., for a 20ms delay multiple, last letter of section 1 with delay of 200ms,
  // and delay for first letter at start section 2 would be 220ms, which is not what we want)
  sectionTitles.forEach(function (
    section // these are needed because each title or desc contains children with spans (letters), so put all spans of each section as one element of array to iterate over.
  ) {
    sectionTitleLetters.push(selectAllToArray(".letter", section));
  });

  if (html.dataset.page === "home") {
    var cardTitles = [];

    benefitsCardTitle.forEach(function (title) {
      cardTitles.push(selectAllToArray(".letter", title));
    });

    var cardDesc = [];

    benefitsCardDesc.forEach(function (desc) {
      cardDesc.push(selectAllToArray(".line", desc));
    });

    var testDescLetters = [];

    testDesc.forEach(function (desc, i) {
      // we reverse letters for desktop animation because we want desc to slide in from the left,
      // last letter first to slid in (also first to slide out upon exit animation);
      testDescLetters.push(
        app.globals.isMobile
          ? testDesc.slice(i, i + 1)
          : selectAllToArray(".letter", desc).reverse()
      );
      // need to use slice above because animate fn expects array even if length is 1
    });
  }

  if (html.dataset.page === "about") {
    var aboutTitleLetters = [];

    aboutTitles.forEach(function (title) {
      aboutTitleLetters.push(selectAllToArray(".letter", title));
    });

    var aboutStoryPartDescParas = [];

    aboutStoryPartDesc.forEach(function (line) {
      aboutStoryPartDescParas.push(selectAllToArray(".line", line));
    });
  }

  // elems common to every page
  app.animations.elementsToAnimate.push(
    benefitsMotif,
    bookUnderline,
    heroCTA,
    contact,
    contactBox,
    contactMotif,
    contactTitle,
    contactCTA,
    contactInputs,
    inputLabels,
    contactPhone,
    contactEmail,
    footerAboutLetters,
    logoFooter,
    footerDescLetters,
    footerNavTitle,
    navItem,
    footerContactTitle,
    addressIcon,
    contactInfo,
    emailIcon,
    phoneIcon,
    followTitle,
    facebookIcon,
    instagramIcon,
    twitterIcon,
    youtubeIcon
  );

  sectionTitleLetters.forEach(function (title) {
    app.animations.elementsToAnimate.push(title);
  });

  if (select("html").dataset.page === "home") {
    app.animations.elementsToAnimate.push(
      heroTitleLetters,
      heroSubtitle,
      heroTagline,
      heroQuotes,
      benefitCardBehind,
      benefitsCardMotif,
      benefitsCardTitle,
      servicesTitles,
      servicesImgsTri,
      servicesImgsArt,
      serviceCardSubTitle,
      serviceCardDesc,
      testMotif,
      testHighlight,
      testPerson,
      testPos,
      testSliderBubbles
    );

    cardTitles.forEach(function (title) {
      app.animations.elementsToAnimate.push(title);
    });

    cardDesc.forEach(function (desc) {
      app.animations.elementsToAnimate.push(desc);
    });

    testDescLetters.forEach(function (letterGroup, i) {
      app.animations.elementsToAnimate.push(letterGroup);
    });
  }

  if (select("html").dataset.page === "about") {
    app.animations.elementsToAnimate.push(
      aboutTitles,
      aboutHeroHeadingLetters,
      aboutHeroSubHeading,
      aboutHeroTagline,
      aboutCard
    );

    aboutTitleLetters.forEach(function (title) {
      app.animations.elementsToAnimate.push(title);
    });

    aboutStoryPartDescParas.forEach(function (para) {
      app.animations.elementsToAnimate.push(para);
    });
  }

  // configure animation props
  app.animations.elementsToAnimate.forEach(function (elems) {
    elems.finishedAnimating = false;

    elems[0].classList.add("animate");

    // delay is only defined with animate class;
    // this is for staggered delays (i.e., elem1: 20ms delay, elem2: 40ms, etc; the multiplicand
    // which uses each elem's index position (i) for a multiplier)
    elems.delayMultiplicand = app.animations.getDurationInMS(
      getComputedStyle(elems[0]).animationDelay
    );
    elems[0].classList.remove("animate"); // remove it since we don't know if it should be animated yet

    // add default animation properties for all elems (helps our other animation fns)
    // GROUPDELAY is delay for entire elem array (i.e., timeout until
    // first elem in array is animated). It is applied once, and set to zero
    // so if only half of the elems get animated, and the other half is scrolled to
    // later and animated, the delay will only apply to first half!
    // DONTCHECKPOS is a flag that tells the function that checks for visibility of
    // elem before animating it to animate it anyway regardless whether it's visible
    // or not (useful for animating some element on page load regardless of where it's at,
    // like how normally adding animations in CSS works, but by using this instead
    // we can let our animate fn calculate the stagger delays for us)
    elems.animationProperties = {
      groupDelay: 0,
      dontCheckPos: false,
    };
  });

  // TRACKINGFN is a function that runs after elem has animate class
  // added to it, but before animation runs (so we can run a cb for
  // animation start, end, iteration, etc). Called tracking since it
  // tracks with either start, end, etc.
  // TRACKINGFNCB is a callback for trackingFN to run
  sectionTitleLetters.forEach(function (title) {
    title.animationProperties = {
      groupDelay: 300,
      trackingFn: app.animations.animationEnd,
      /*trackingFnCb: app.animations.displaySpansInline*/
    };
  });

  contactInputs.animationProperties = {
    groupDelay: 500,
  };

  inputLabels.animationProperties = {
    groupDelay: 500,
    trackingFn: app.animations.animationEnd,
    trackingFnCb: app.animations.removeAnimation,
  };

  footerAboutLetters.animationProperties = {
    /*trackingFn: app.animations.animationEnd,
        trackingFnCb: app.animations.displaySpansInline*/
  };

  footerDescLetters.animationProperties = {
    groupDelay: 100,
    /*trackingFn: app.animations.animationEnd,
        trackingFnCb: app.animations.displaySpansInline*/
  };

  navItem.animationProperties = {
    groupDelay: 200,
  };

  facebookIcon.animationProperties = {
    trackingFn: app.animations.animationEnd,
    trackingFnCb: app.animations.removeAnimation,
  };

  twitterIcon.animationProperties = {
    trackingFn: app.animations.animationEnd,
    trackingFnCb: app.animations.removeAnimation,
  };

  youtubeIcon.animationProperties = {
    trackingFn: app.animations.animationEnd,
    trackingFnCb: app.animations.removeAnimation,
  };

  instagramIcon.animationProperties = {
    trackingFn: app.animations.animationEnd,
    trackingFnCb: app.animations.removeAnimation,
  };

  contactInfo.animationProperties = {
    groupDelay: 300,
  };

  if (select("html").dataset.page === "home") {
    heroTitleLetters.animationProperties = {
      trackingFn: app.animations.animationEnd,
      trackingFnCb: app.animations.displaySpansInline,
    };

    heroQuotes.animationProperties = {
      groupDelay: 1200,
    };

    cardTitles.forEach(function (title) {
      title.animationProperties = {
        groupDelay: 0,
        trackingFn: app.animations.animationEnd,
        /*trackingFnCb: app.animations.displaySpansInline,*/
      };
    });

    serviceCardDesc.animationProperties = {
      groupDelay: 200,
    };

    testHighlight.animationProperties = {
      dontCheckPos: true,
    };

    // add animate class to calculate groupDelay for each group of testimonial letters (for desktop animation)
    // or each testimonial as a whole (each whole paragraph)
    testDescLetters[0][0].classList.add("animate");
    var testDescsGroupDelay =
      app.animations.getDurationInMS(
        getComputedStyle(testDescLetters[0][0]).animationDuration
      ) / testDescLetters.length;
    var lettersDelay = app.animations.getDurationInMS(
      getComputedStyle(testDescLetters[0][0]).animationDelay
    );
    testDescLetters[0][0].style.animation = "none";
    testDescLetters[0][0].offsetHeight;
    testDescLetters[0][0].classList.remove("animate");
    testDescLetters[0][0].style.animation = ""; // not a mistake. '' needs to be set in addition to 'none' else sometimes the animation won't be removed.

    testDescLetters.forEach(function (letterGroup, i) {
      letterGroup.animationProperties = {
        dontCheckPos: true,
      };

      // have to manually add the delays for each letter,
      // since each desc is delayed by the animation time
      // of the prev desc, plus the individual letter delays
      // (which isn't something we can get from the CSS).
      letterGroup.forEach(function (letter, j) {
        letter.uniqueDelay =
          testDescsGroupDelay * i +
          (letterGroup.length > 1 ? j * lettersDelay : 0);
      });
    });

    testPerson.animationProperties = {
      dontCheckPos: true,
    };

    testPos.animationProperties = {
      dontCheckPos: true,
    };

    testSliderBubbles.animationProperties = {
      dontCheckPos: true,
    };
  }

  if (select("html").dataset.page === "about") {
    aboutHeroHeadingLetters.animationProperties = {
      groupDelay: 500,
      trackingFn: app.animations.animationEnd,
      trackingFnCb: app.animations.displaySpansInline,
    };

    aboutTitleLetters[0].animationProperties.groupDelay = 100; //About Us

    for (var i = 1; i < aboutTitleLetters.length; i++) {
      aboutTitleLetters[i].animationProperties.groupDelay = 500;
    }

    // unGroup interprets animation delay as constant for each elem
    // in a group instead of using staggered delays
    // (e.g., for a series of square cards on page 20ms delay means
    // each card is delayed by 20ms whereas with staggered delays
    // each card is delayed by multiple of 20, starting with 0)
    aboutCard.forEach(function (card) {
      card.classList.add("unGroup");
    });

    aboutStoryPartDescParas.forEach(function (para) {
      para.animationProperties = {
        groupDelay: 400,
        dontCheckPos: false,
      };
    });
  }
};

app.animations.requestAnimationUpdate = function () {
  // use the below update check so we don't
  // do two updates in the same frame, which can happen
  // if a resize event accidentally triggers a scroll event simultaneously
  // and they both use this same handler
  if (!app.state.isUpdating) {
    // using 3 vars so it's easier to read below
    var setDynamicAnimationProps = app.animations.setDynamicAnimationProps;
    var updateAnimations = app.animations.updateAnimations;
    var elementsToAnimate = app.animations.elementsToAnimate;

    app.state.isUpdating = true;
    elementsToAnimate.forEach(setDynamicAnimationProps); // batch reading/recording
    requestAnimationFrame(updateAnimations); // batch writing (change styles, add animation class, etc)
  }
};

app.animations.updateAnimations = function () {
  app.animations.elementsToAnimate.forEach(app.animations.animate);
  app.state.isUpdating = false;
};

//@Params
// elems: group of related elements that have same animation attached to them
// i: some index of elems
// staggeredDelay: calculated by multiplying delayMultiplicand by index i.
//      It's the default delay method used for animations in this app (different from css animation-delay prop)
// delayMultiplicand: the css animation-delay property this app uses along with its multiples (delayMultiplicand * i)
//      to calculate animation delay for some elems[i] in a group of elems.
app.animations.setAnimationDelay = function (
  elems,
  i,
  staggeredDelay,
  delayMultiplicand
) {
  // figure out if we should delay all elems by constant value (just use multiplicand)
  // or use staggered delays (based on delayMultiplicand * i)
  var delay =
    elems.length === 1 || elems[i].delayByConstant
      ? delayMultiplicand + "ms"
      : staggeredDelay + "ms";

  // individual elems can also have a unique delay whether using staggered or constant delays;
  // 0.1 below is a special value we use in css to signify this element actually has a delay.
  // (Because we originally define uniqueDelay by getting an elem's computedStyle, which will
  // return 0 always if no delay or animation is attached).
  delay = elems[i].uniqueDelay >= 0.1 ? elems[i].uniqueDelay + "ms" : delay;

  elems[i].delay = delay || 0 + "ms"; // store delay in variable so we don't cause a style recalc here
};

// props likely to change per frame (note: we do all the reading of the DOM here).
// fn calcs dynamic elem properties like position (to see if elem in view)
// and animation delay, which can change based on order of elems in a group
// and whether previous elems have been animated already or not.
app.animations.setDynamicAnimationProps = function (elems) {
  if (elems.finishedAnimating) {
    return;
  }

  for (var i = 0; i < elems.length; i++) {
    if (!elems.animationProperties.dontCheckPos) {
      var elementCoords = elems[i].getBoundingClientRect();
      elems[i].top = elementCoords.top;
      elems[i].bottom = elementCoords.bottom;
    }

    // UnGroup class signifies it should break from the group's stagger
    // and use own delay (just the delayMultiplicand in that case)
    elems[i].delayByConstant = elems[i].className.indexOf("unGroup") !== -1;

    elems[i].uniqueDelay =
      elems[i].uniqueDelay ||
      app.animations.getDurationInMS(getComputedStyle(elems[i]).animationDelay);
  }

  // Need this because we want the stagger to be
  // based only on the number of elems visible, not just delay * i.
  // This way we don't have parts of an elem group that are further
  // down the page animate in with delays staggered based on ones
  // that were previously animated in some time ago.
  var numVisible = 0;
  var animationDelay = 0; // we always start stagger at 0
  var i = 0;

  while (i < elems.length) {
    if (
      app.animations.isVisible(elems[i]) ||
      elems.animationProperties.dontCheckPos
    ) {
      app.animations.setAnimationDelay(
        elems,
        i,
        animationDelay,
        elems.delayMultiplicand
      );
      numVisible++;

      elems.groupIsVisible = true;
    }

    i++;
    animationDelay = elems.delayMultiplicand * numVisible;
  }
};

// add class animate with css animation defined for array of elems.
// fn typically assumes setDynamicAnimationProps fn called before this
// on elems (though it's unneeded if style.animationDelay is already set or
// elems has animatitonProperties and groupIsVisible properties attached to it)
app.animations.animate = function (elems) {
  if (elems.finishedAnimating) {
    return;
  }

  if (elems.animationProperties.groupDelay > 0 && elems.groupIsVisible) {
    var id = 0;

    id = setTimeout(function () {
      // set groupDelay to 0 since groupDelay is only supposed to
      // be applied once to all elems as a whole
      // and subsequent calls to animate, for unanimated elems in
      // elems array, shouldn't delay as group again
      elems.animationProperties.groupDelay = 0;

      requestAnimationFrame(function () {
        app.animations.animate(elems);
      });
    }, elems.animationProperties.groupDelay);

    app.state.timeoutIds.add(id);
  } else if (elems.length > 0) {
    // track animated elems (spliced out of array when animated)
    // for purpose of running some cleanup or tracking function(trackingFnCb) on
    // them
    var animatedElems = [];

    for (var i = 0; i < elems.length; i++) {
      if (
        app.animations.isVisible(elems[i]) ||
        elems.animationProperties.dontCheckPos
      ) {
        elems[i].style.animationDelay = elems[i].delay; // if delay is undefined, style.animationDelay will stay unaltered
        elems[i].classList.add("animate");
        animatedElems.push(elems.splice(i, 1)[0]);
        i--; // because elems array shrunk
      }
    }

    if (elems.animationProperties.trackingFn) {
      elems.animationProperties.trackingFn(
        animatedElems,
        elems.animationProperties.trackingFnCb
      );
    }

    if (0 === elems.length) {
      // so we don't run animate function on these elems again
      elems.finishedAnimating = true;
    }
  }
};

/* MENU FUNCTIONS
==========================================================================================*/

app.menu.closeMenuAtDesktopScreenSize = function () {
  // need all three because each browser will only use one and
  // ignore the others
  var screenWidth =
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth;

  if (screenWidth >= app.globals.MOBILE_SIZE) {
    app.menu.closeMenu();
  }
};

// Need this because website is structured like a single
// page website, with homepage containing multiple sections usually found on separate
// site pages. Changes active nav link to the one whose corresponding section is closest
// to the top of the window.
app.menu.changeActiveNavLink = function () {
  // each menuLink id will match its corresponding section id
  var menuLinks = app.selectAllToArray(".menu__link");

  // all sections except testimonial and about-story since they don't have menu links
  var sections = app.selectAllToArray("section:not(.test):not(.about-story)");
  var indexOfSectionClosestToTop = 0;

  sections.forEach(function (section, i) {
    section.top = section.getBoundingClientRect().top;

    if (
      Math.abs(section.top) <=
      Math.abs(sections[indexOfSectionClosestToTop].top)
    ) {
      indexOfSectionClosestToTop = i;
    }
  });

  menuLinks.forEach(function (link) {
    var linkId = link.hash.split("#")[1];
    if (linkId === sections[indexOfSectionClosestToTop].id) {
      menuLinks.forEach(function (link) {
        link.classList.remove("active"); // because a new link is becoming active
      });

      link.classList.add("active");

      return;
    }
  });
};

// fix header, with nav menu, to top of page if user scrolled enough
app.menu.toggleFixedHeader = function () {
  var select = app.select;

  var hamburgerToggle = select(".main-nav__toggle-menu");
  var menu = select(".menu");
  var header = select("header");
  var logo = select(".main-nav__logo-wrapper");

  // 20 pixel offset from top of header, which should be zero since at top of page,
  // so user can scroll for a bit before header sticks to top
  var headerTop = 20;

  if (window.pageYOffset > headerTop) {
    header.classList.add("scrolled");
    logo.classList.add("scrolled");
    hamburgerToggle.classList.add("scrolled");
    menu.classList.add("scrolled");
  }

  // header is back at top of page/not scrolled.
  // also check for menu open above too because
  // we don't want mobile menu to jump to unfixed position when still open
  else if (0 === window.pageYOffset && -1 === menu.className.indexOf("open")) {
    header.classList.remove("scrolled");
    logo.classList.remove("scrolled");
    hamburgerToggle.classList.remove("scrolled");
    menu.classList.remove("scrolled");
  }
};

app.menu.toggleMenu = function () {
  var menu = app.select(".menu");
  var menuToggleBtn = app.select(".main-nav__toggle-menu");

  menu.classList.toggle("open");
  menuToggleBtn.classList.toggle("open");

  app.menu.toggleFixedHeader();
  app.menu.animateLinks();
};

// when menu opens, makes the links
// animate in nicely
app.menu.animateLinks = function () {
  var menu = app.select(".menu");
  var headerLinks = [].slice.call(menu.children);
  headerLinks.animationProperties = {
    // need to setup animation props so we can animate links on menu open
    groupDelay: 0,
    dontCheckPos: true,
  };

  if (menu.className.indexOf("open") !== -1) {
    app.animations.animate(headerLinks);
  } // menu closing so reset animation state so we can animate it again upon reopen
  else {
    headerLinks.forEach(function (link) {
      link.classList.remove("animate");
    });
  }
};

app.menu.closeMenu = function () {
  var toggleBtn = app.select(".main-nav__toggle-menu");

  if (toggleBtn.className.indexOf("open") !== -1) {
    app.menu.toggleMenu();
  }
};

/* APP LISTENERS
=====================================================================================================*/

// MENU READY:

app.ready(function handleMobileMenuFunctionality() {
  // Note: this only closes the menu when we click
  // outside of it because of the more specific
  // handlers below (that stop propagation).
  app.listen(window, "click", app.menu.closeMenu);

  var hamburgerBtn = ".main-nav__toggle-menu";
  app.listen(hamburgerBtn, "click", app.menu.toggleMenu);

  var menu = app.select(".menu");
  var header = app.select("header");

  app.listenAll(
    [header, menu],
    "click",
    function ignoreClicksOnHeaderAndInsideMenu(e) {
      if (menu.className.indexOf("open") !== -1) {
        if (e.currentTarget === header) {
          e.preventDefault(); // so when logo clicked in header it doesn't follow link
        }

        // only want menu to close when clicking outside of menu and header
        // (or on hamburger) so we stop bubbling so default window click listener
        // doesn't fire
        e.stopPropagation();
      }
    }
  );

  app.listen(
    window,
    "resize",
    app.throttle(app.menu.closeMenuAtDesktopScreenSize, 400)
  );
});

app.ready(function changeHeaderOnScroll() {
  app.listen(window, "scroll", app.throttle(app.menu.changeActiveNavLink, 150));
  app.listen(window, "scroll", app.throttle(app.menu.toggleFixedHeader, 350));
});

// ANIMATIONS READY:

app.ready(function runInitialAnimations() {
  app.animations.readyElementsForAnimation();

  app.animations.requestAnimationUpdate(); // for first animation update since no scroll event on dcl

  app.menu.changeActiveNavLink();
  app.menu.toggleFixedHeader();

  setTimeout(app.preventLayoutResize, 100);
});

app.ready(function animateVisibleElemsOnScroll() {
  app.listen(window, "scroll", app.animations.requestAnimationUpdate);
});

app.ready(function animateVisibleElemsOnResize() {
  app.listen(
    window,
    "resize",
    app.throttle(function () {
      app.state.windowHeight = window.innerHeight;
      app.preventLayoutResize();
    }),
    150
  );

  app.listen(window, "resize", app.animations.requestAnimationUpdate);
});

app.ready(function animateTestimonialChange() {
  app.listenAll(
    ".test__slider__bubble",
    "click",
    app.testimonial.handleTestimonialSwitch
  );
});

app.ready(function animateSmoothScroll() {
  app.listenAll(".scroll", "click", app.smoothScroll.scrollToSection);
});
// reminder to self: the order of the above listeners matters.
// if we put this one first, before readyElementsForAnimation is
// called, then this scroll listener won't fire for the scroll link
// with inner text wrapped (because readyElementsForAnimation
// wraps the text and this sets the listener on the unchanged elem,
// therefore it never fires)

// CONTACT READY:

app.ready(function handleContactFormSubmit() {
  app.listen("#contact-form", "submit", function handleValidationAndXHR(e) {
    var submitBtn = app.select("#submit");
    submitBtn.disabled = true; // so multiple clicks won't send multiple identical emails

    // because we're doing xhr to server
    e.preventDefault();

    var validationResult = app.contact.validateInputs();

    if (validationResult.failedClientValidation) {
      app.contact.displayValidationErrors(validationResult);
      submitBtn.disabled = false;
    } // validateInputs sent req with form data, so see if server validation passed
    else {
      var xhr = validationResult.xhr;

      xhr.onload = function () {
        var response = JSON.parse(xhr.response);

        if (typeof response.validationErrors !== "undefined") {
          validationResult.failedServerValidation = true;
          validationResult.validationErrors = response.validationErrors;

          app.contact.displayValidationErrors(validationResult);
          submitBtn.disabled = false;
        } else {
          app.contact.animateOutExistingFormElements();
          app.contact.animateInSendResultMsg(response);
        }
      };

      xhr.onerror = function () {
        validationResult.connectionError = true;
        app.contact.displayValidationErrors(validationResult);
        submitBtn.disabled = false;
      };
    }
  });
});

export default app;
